import React from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { styled } from '@mui/material/styles';
import { Typography, Grid, Link, Container } from '@mui/material';
import { Crisp } from "crisp-sdk-web";

Crisp.configure('aa75801e-efc7-4c04-b25c-4f856c52dc0f');

const StyledApp = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

const StyledTypographyH1 = styled(Typography)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

const StyledTypographyBody1 = styled(Typography)(({theme}) => ({
    color: theme.palette.common.white,
    textAlign: 'center'
}))

const StyledImage = styled('img')(({theme}) => ({
    cursor: 'pointer',
    boxShadow: theme.shadows[8],
    transition: 'transform 0.3s ease',
    ['&:hover']: {
        boxShadow: theme.shadows[20],
        transform: 'scale(1.1)'
    }
}))

function App() {
  return (
    <StyledApp>
        <Container maxWidth="lg">
            <Grid container direction="column" alignItems="center" spacing={1}>
                <Grid item>
                    <StyledTypographyH1 variant="h1">Hoi!</StyledTypographyH1>
                </Grid>
                <Grid item>
                    <StyledTypographyBody1 variant="body1">My name is Tibi, a software engineer. <Link onClick={() => Crisp.chat.open()} style={{ whiteSpace: 'nowrap'}}>Chat with me!</Link></StyledTypographyBody1>
                </Grid>
                <Grid item>
                    <Link href="https://www.buymeacoffee.com/tibijuhaszw" title="buy me coffee" target="_blank">
                        <StyledImage src={process.env.PUBLIC_URL + '/assets/bmc_qr.png'} alt="buy me a coffee" style={{ width: '120px' }} />
                    </Link>
                </Grid>
            </Grid>
        </Container>
    </StyledApp>
  );
}

export default App;
